import React from 'react';
import Timeline from './Timeline';
import DataCV from '../../data/resume.json';
import TryHackMeBadgeProfile from '../Custom/TryHackMeBadgeProfile';

const TimeLineBloc = () => {
    return (
        <div className="row">
            <div className=" col-xs-12 col-sm-6 ">
                <div className="block-title">
                    <h2>Education</h2>
                </div>
                
                <div id="timeline_1" className="timeline clearfix">
                <Timeline  key="thm" CompanyName="TryHackMe.com" FunctionName="Formation Sécurité Informatique" Summary={'Apprentissage en cours "SOC Level 1" \nApprentissage terminé :\n"Cyber securtity 101"\n"Jr Penetration Tester"\n'} StartDate="2024-12-01" EndDate="TODAY">
                <TryHackMeBadgeProfile userPublicId="3776538" />
                </Timeline>
                    {DataCV.education.sort((a, b) => new Date(b.startDate) - new Date(a.startDate)).map((item, index) => (
                        <Timeline  key={index} CompanyName={item.institution} FunctionName={item.studyType} Summary={item.courses.join("\n")} StartDate={item.startDate} EndDate={item.endDate}/>
                    ))}
                </div>
            </div>
            <div className=" col-xs-12 col-sm-6 ">
                <div className="block-title">
                    <h2>Experience</h2>
                </div>
                <div id="timeline_2" className="timeline clearfix">
                    {DataCV.work.sort((a, b) => new Date(b.startDate) - new Date(a.startDate)).map((item, index) => (
                        <Timeline  key={index} CompanyName={item.name} FunctionName={item.position} Summary={item.summary} StartDate={item.startDate} EndDate={item.endDate}/>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TimeLineBloc;