import React from 'react';
import PropTypes from 'prop-types';

const Certificate = ({CertName,Obtentiondate, CertId, CertEtablissement,LogoUrl,schoolUrl}) => {
    return (
        <div className=" col-xs-12 col-sm-4 mb-2">
            <a href={schoolUrl} target='_blank' rel="noreferrer">
                <div className="certificate-item clearfix">
                <div className="certi-logo">
                    <img src={LogoUrl} alt="logo"></img>
                </div>

                <div className="certi-content">
                    <div className="certi-title">
                    <h4>{CertName}</h4>
                    </div>

                    <div className="certi-id">
                    <span>{CertId}</span>
                    </div>
                    <div className="certi-date">
                    <span>{Obtentiondate}</span>
                    </div>

                    <div className="certi-company">
                    <span>{CertEtablissement}</span>
                    </div>
                </div>
                </div>
            </a>
        </div>
    );
};

Certificate.propTypes = {
    CertName: PropTypes.string,
    CertEtablissement: PropTypes.string,
    CertId: PropTypes.string,
    Obtentiondate: PropTypes.string,
    LogoUrl: PropTypes.string,
    schoolUrl: PropTypes.string    
};

export default Certificate;